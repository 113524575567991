var config = {
  // Set the Demo Version
  demo: false,

  //SEO Configurations
  appName: "Islam Quiz Spel",
  metaDescription: "Islam Quiz Spel is a Web Quiz Application",
  metaKeyWords: "Islam Quiz,Quiz,Questions,Answers,Online Quiz",
  // googleAddsense
  // googleAddsense: "",

  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://admin.islamspel.nl/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur", "nl"],
  defaultLanguage: "nl",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "nl", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations

  apiKey: "AIzaSyDv-AKYpBfewpqkBEFPtwTYiSW7y39scsQ",
  authDomain: "quizapp-2aad9.firebaseapp.com",
  projectId: "quizapp-2aad9",
  storageBucket: "quizapp-2aad9.appspot.com",
  messagingSenderId: "73531609229",
  appId: "1:73531609229:web:90acb55d5b61758ba4d2c4",
  measurementId: "G-6HBTGYWX6N",

  //footer area
  companytext: "Islam Quiz Spel gemaakt om wille van Allaah.",
  addresstext: "Address: Noord-Brabant, Nederland.",
  phonenumber: "+316 86170488",
  email: "vragen@oemmah.nl",
  facebooklink: "https://www.facebook.com/oemmah",
  instagramlink: "https://www.instagram.com/oemmah",
  linkedinlink: "https://www.linkedin.com/company/oemmah",
  weblink: "https://islamspel.nl/",
  companyname: "Islam Quiz Spel",
};

export default config;
